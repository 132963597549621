export const environment = {
    production: false,
    apiEndpoint: 'https://api.a.rykersoft.com',
    defaultImage: 'https://asel-icons.imgix.net/default-food-v5.png',
    defaultMenuImage: 'assets/img/default-menu-image-v5.png',
    defaultRestaurantImage: 'https://asel-icons.imgix.net/default-background-v2.jpg',
    defaultAvatar: 'https://asel-icons.imgix.net/default-avatar.png',
    emailLogo: 'https://asel-icons.imgix.net/email-logo.png',
    mmmmLogo: 'https://asel-icons.imgix.net/mmmm-logo.svg',
    favoritesBackgroundImage: 'https://asel-icons.imgix.net/favorites-background-v2.jpeg',
    exploreBackgroundImage: 'https://asel-icons.imgix.net/explore-background-v3.jpeg',
    exploreBackgroundDesktopImage: 'https://asel-icons.imgix.net/explore-background-desktop-v3.jpg',
    loginBackgroundImage: 'https://asel-icons.imgix.net/login-background.jpg',
    gemBackgroundImage: 'https://asel-icons.imgix.net/gem-background.jpeg',
    instagramAppId: '859566161185129',
    facebookAppId: '235522254305982',
    domain: 'a.rykersoft.com',
    defaultRestaurantHost: 'yoursite',
    googleClientId: '632551795551-135spj9hrje3u4pg1u03g422gvsogsr6.apps.googleusercontent.com',
    paidFeaturesEnabled: false,
    getstreamApiKey: 'tfbqvnaznjvv',
    getstreamAppId: '1125539',
    imgixDomain: 'img.a.rykersoft.com',
    ipLookupKey: '3K5ZRaNbltsucYsxF15u',
    adminBackgroundImage: {
        url: 'https://images.unsplash.com/photo-1543164939-264c96ac5b6a?ixlib=rb-1.2.1&auto=format&fit=crop',
        blurHash: 'eG8gjV9ZIoxajF%hRPaxj]f60f-U%1NHW;9Y%2kCaebH#%XANIs,oL'
    },
    timeBlockBackgroundImage: 'https://asel-icons.imgix.net/time-block-background-3.png',
    membershipDesktopBackground: 'https://asel-icons.imgix.net/mbership-background.jpg',
    mobileBackgroundImage: {
        url: 'https://unsplash.com/collections/lh9FM2B1bv8/backgrounds-mobile'
    },
    mmmGirlSound: 'assets/sounds/mmmm-girl-2.mp3'
};
