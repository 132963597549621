<div *ngIf="!isDesktop" class="top-input-modal safe-area-top">
  <app-video-modal-input
    [searchParams]="searchParams"
    [type]="type"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal-input>
</div>

<div *ngIf="isDesktop" class="d-flex w-100 h-100 row m-0">
  <div class="col-6 bg-white d-flex justify-content-center">
    <div [appBackgroundUrl]="backgroundImage ? backgroundImage: ''" class="position-absolute-0">
      <div class="mt-3-5rem font-weight-bold section-title d-flex justify-content-center">
        {{ searchParams.locationName }}
      </div>
    </div>
  </div>

  <div class="col-6 bg-dark">
    <div class="bottom-section d-flex flex-column mx-2rem pt-3">
      <app-video-modal-input
        [searchParams]="searchParams"
        [inputPlaceholder]="!submitClicked ? 'Link a video of a dining tour': 'URL required'"
        [textInput]="'For ' + searchParams.locationName"
        [type]="TourType.DINING"
        (videoAdded)="onVideoAdded($event)"
      ></app-video-modal-input>
    </div>

    <div class="bottom-section d-flex flex-column mx-2rem pt-3">
      <app-video-modal-input
        [searchParams]="searchParams"
        [inputPlaceholder]="!submitClicked ? 'Link a video of a walking tour': 'URL required'"
        [textInput]="'YouTube • TikTok • Rumble • etc'"
        [type]="TourType.WALKING"
        (videoAdded)="onVideoAdded($event)"
      ></app-video-modal-input>
    </div>
  </div>
</div>
